<template>
  <div>
    <permession-list-add-new
      :is-add-new-permession-sidebar-active.sync="
        isAddNewPermessionSidebarActive
      "
      :role-options="roleOptions"
      @refetch-data="refetchData"
    />

    <b-card-text class="text">
      Each category (Basic, Professional, and Business) includes the four
      predefined roles shown below.
    </b-card-text>

    <!-- modal vertical center -->
    <b-modal
      id="modal-edit"
      centered
      header-bg-variant="transparent"
      hide-footer
    >
      <div class="text-center mb-1">
        <h2 text-variant="secondry">Edit permission</h2>
        <b-card-text text-variant="secondry">
          Edit permission as per your requirements.
        </b-card-text>
      </div>
      <b-card
        header="Warning!"
        header-tag="header"
        text-variant="warning"
        class="text-left bg-variant mb-1"
      >
        <b-card-text
          >By editing the permission name, you might break the system
          permissions functionality. Please ensure you're absolutely certain
          before proceeding.</b-card-text
        >
      </b-card>

      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form>
          <label for="permission-name">Permission Name</label>
          <b-row no-gutters>
            <b-col md="9">
              <b-form-group>
                <validation-provider
                  #default="{ errors }"
                  name="permession name"
                  rules="required|alpha"
                >
                  <b-form-input
                    id="permission-name"
                    v-model="permissionName"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Enter a permession name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3" class="text-right">
              <b-button
                variant="primary"
                type="submit"
                @click.prevent="validationForm"
              >
                Update
              </b-button>
            </b-col>
            <b-col cols="12">
              <b-form-group class="core-permission">
                <b-form-checkbox
                  id="core-permission"
                  v-model="corePermission"
                  class="mr-50 cursor-pointer"
                />
                <label for="core-permission" class="cursor-pointer"
                  >Set as core permission</label
                >
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="3"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search & Role -->
          <b-col cols="12" md="9">
            <div class="d-flex align-items-center justify-content-end">
              <label class="mr-50">Search:</label>
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1 search"
              />
              <!-- Role -->
              <permessions-list-filters
                :role-filter.sync="roleFilter"
                :role-options="roleOptions"
              />

              <b-button
                variant="primary"
                @click="isAddNewPermessionSidebarActive = true"
              >
                <span class="text-nowrap">Add Permession</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refPermessionListTable"
        class="position-relative"
        :items="fetchPermessions"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Assigned To -->
        <template #cell(assignedTo)="data">
          <div
            v-for="role in data.item.role"
            :key="role"
            class="d-inline-block"
          >
            <b-link
              v-if="role !== 'select role'"
              :to="{ name: 'apps-users-list', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap mr-50"
            >
              <b-badge
                pill
                :variant="`light-${resolvePermessionRoleVariant(role)}`"
                class="text-capitalize"
              >
                {{ role }}
              </b-badge>
            </b-link>
          </div>
        </template>

        <!-- Column: Permession Name -->
        <template #cell(name)="data">
          {{ data.item.permessionName }}
        </template>

        <!-- Column: email -->
        <template #cell(createdDate)>
          {{
            `${date.getDate()} ${
              month[date.getMonth()]
            } ${date.getFullYear()}, ${date.getHours()}:${date.getMinutes()}`
          }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)>
          <feather-icon
            icon="EditIcon"
            class="mr-1 cursor-pointer"
            v-b-modal.modal-edit
          />
          <feather-icon icon="TrashIcon" class="cursor-pointer" />
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalPermessions"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BFormInput,
  BFormCheckbox,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BModal,
  VBModal,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alpha } from "@validations";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import PermessionsListFilters from "./PermessionsListFilters.vue";
import usePermessionsList from "./usePermessionsList";
import permessionStoreModule from "../permessionStoreModule";
import PermessionListAddNew from "./PermessionListAddNew.vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    PermessionsListFilters,
    PermessionListAddNew,

    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BFormInput,
    BFormCheckbox,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BModal,

    vSelect,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      permissionName: "",
      corePermission: false,
      required,
      alpha,
      date: new Date(),
      month: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  },
  methods: {
    validationForm() {
      // eslint-disable-next-line arrow-parens
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          alert("form submitted!");
        }
      });
    },
  },

  setup() {
    const PERMESSION_APP_STORE_MODULE_NAME = "app-permession";

    // Register module
    // eslint-disable-next-line curly
    if (!store.hasModule(PERMESSION_APP_STORE_MODULE_NAME))
      // eslint-disable-next-line nonblock-statement-body-position
      store.registerModule(
        PERMESSION_APP_STORE_MODULE_NAME,
        // eslint-disable-next-line comma-dangle
        permessionStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(PERMESSION_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(PERMESSION_APP_STORE_MODULE_NAME);
      }
    });

    const isAddNewPermessionSidebarActive = ref(false);

    const roleOptions = [
      { label: "Select Role", value: "select role" },
      { label: "Administrator", value: "administrator" },
      { label: "Manager", value: "manager" },
      { label: "User", value: "user" },
      { label: "Support", value: "support" },
      { label: "Restricted User", value: "restricted user" },
    ];

    const {
      fetchPermessions,
      tableColumns,
      perPage,
      currentPage,
      totalPermessions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPermessionListTable,
      refetchData,

      // UI
      resolvePermessionRoleVariant,

      // Extra Filters
      roleFilter,
    } = usePermessionsList();

    return {
      isAddNewPermessionSidebarActive,

      fetchPermessions,
      tableColumns,
      perPage,
      currentPage,
      totalPermessions,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refPermessionListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolvePermessionRoleVariant,

      roleOptions,

      // Extra Filters
      roleFilter,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.search {
  max-width: 200px;
}
.text {
  margin-top: -10px;
}
.bg-variant {
  background-color: #ff9f432e;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.core-permission > div {
  display: flex;
}
</style>
