<template>
  <div id="users-permessions">
    <permessions-list />
  </div>
</template>

<script>
import PermessionsList from "./permessions-list/PermessionsList.vue";

export default {
  name: "UsersPermessions",
  components: {
    PermessionsList,
  },
};
</script>
