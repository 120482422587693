<template>
  <b-modal
    id="add-new-permession-sidebar"
    :visible="isAddNewPermessionSidebarActive"
    no-header
    centered
    hide-footer
    header-bg-variant="transparent"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-permession-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <div class="text-center">
        <h2>Add New Permission</h2>
        <b-card-text
          >Permissions you may use and assign to your users.</b-card-text
        >
      </div>
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- Permession Name -->
          <validation-provider
            #default="validationContext"
            name="Permission Name"
            rules="required"
          >
            <b-form-group label="Permission Name" label-for="permession-name">
              <b-form-input
                id="permession-name"
                v-model="permessionData.permessionName"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="Permission Name"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group class="core-permission">
            <b-form-checkbox
              id="core-permission"
              v-model="corePermission"
              class="mr-50 cursor-pointer"
            />
            <label for="core-permission" class="cursor-pointer"
              >Set as core permission</label
            >
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2 justify-content-center">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
            >
              Create Permession
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Discard
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCardText,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";

export default {
  components: {
    BModal,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCardText,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewPermessionSidebarActive",
    event: "update:is-add-new-permession-sidebar-active",
  },
  props: {
    isAddNewPermessionSidebarActive: {
      type: Boolean,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      countries,
      corePermission: false,
    };
  },
  setup(props, { emit }) {
    const blankPermessionData = {
      permessionName: "",
      email: "",
      role: null,
    };

    const permessionData = ref(JSON.parse(JSON.stringify(blankPermessionData)));
    const resetPermessionData = () => {
      permessionData.value = JSON.parse(JSON.stringify(blankPermessionData));
    };

    const onSubmit = () => {
      store
        .dispatch("app-permession/addPermession", permessionData.value)
        .then(() => {
          emit("refetch-data");
          emit("update:is-add-new-permession-sidebar-active", false);
        });
    };

    // eslint-disable-next-line operator-linebreak
    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetPermessionData);

    return {
      permessionData,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
.core-permission > div {
  display: flex;
}
</style>
