import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function usePermessionsList() {
  // Use toast
  const toast = useToast();

  const refPermessionListTable = ref(null);

  // Table Handlers
  const tableColumns = [
    { key: "name", sortable: true },
    { key: "assignedTo", sortable: true },
    { key: "createdDate", sortable: true },
    { key: "actions" },
  ];
  const perPage = ref(10);
  const totalPermessions = ref(0);
  const currentPage = ref(1);
  const perPageOptions = [10, 25, 50, 100];
  const searchQuery = ref("");
  const sortBy = ref("id");
  const isSortDirDesc = ref(true);
  const roleFilter = ref(null);

  const dataMeta = computed(() => {
    const localItemsCount = refPermessionListTable.value ? refPermessionListTable.value.localItems.length : 0;
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalPermessions.value,
    };
  });

  const refetchData = () => {
    refPermessionListTable.value.refresh();
  };

  watch([currentPage, perPage, searchQuery, roleFilter], () => {
    refetchData();
  });

  const fetchPermessions = (ctx, callback) => {
    store
      .dispatch("app-permession/fetchPermessions", {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        role: roleFilter.value,
      })
      .then(response => {
        const { permessions, total } = response.data;

        callback(permessions);
        totalPermessions.value = total;
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching permessions list",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      });
  };

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolvePermessionRoleVariant = role => {
    if (role === "select role") return "primary";
    if (role === "administrator") return "primary";
    if (role === "manager") return "warning";
    if (role === "user") return "success";
    if (role === "support") return "info";
    if (role === "restricted user") return "danger";
    return "primary";
  };

  return {
    fetchPermessions,
    tableColumns,
    perPage,
    currentPage,
    totalPermessions,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refPermessionListTable,

    resolvePermessionRoleVariant,
    refetchData,

    // Extra Filters
    roleFilter,
  };
}
