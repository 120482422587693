import axios from "@axios";

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchPermessions(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get("/apps/permessions", { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    fetchPermession(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/permessions/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
    addPermession(ctx, permessionData) {
      return new Promise((resolve, reject) => {
        axios
          .post("/apps/permessions", { permession: permessionData })
          .then(response => resolve(response))
          .catch(error => reject(error));
      });
    },
  },
};
