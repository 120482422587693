<template>
  <div class="mr-1">
    <v-select
      v-model="defaultRole"
      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
      :value="roleFilter"
      :options="roleOptions"
      class="w-100 select-role"
      :reduce="(val) => val.value"
      @input="(val) => $emit('update:roleFilter', val)"
    />
  </div>
</template>

<script>
import vSelect from "vue-select";

export default {
  components: {
    vSelect,
  },
  props: {
    roleFilter: {
      type: [String, null],
      default: null,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      defaultRole: "Select Role",
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.select-role {
  min-width: 160px;
}
</style>
